import React from 'react';
import { Feature } from '../../components';
import './aboutMe.css';

const aboutMe = () => {
  return (
    <div className='experience section__margin' id='professional_experience'>
      <div className='aboutMe_section_title'>
        <h1>
          Professional Experience
        </h1>
      </div>
      <div className='experience-container'>
        <Feature title='nChain' text='Product Analyst - June 2023 (current)' />
        <Feature title='Deloitte Canada - Omnia AI - Intelligent Automation' text='Analyst - September 2021 to February 2023' />
        <Feature title='Shift Cycle' text='Operations Manager - May to August 2020'/>
        <Feature title='Bank Julius Baer' text='Front Office intern - June to July 2019'/>
        <Feature title='Compagnie Monegasque de Banque' text='Back-Office intern - June to July 2018'/>
      </div>

    </div>
  )
}

export default aboutMe