import React from 'react';
import { Feature } from '../../components';
import './education.css';

const Education = () => {
  return (
    <div className='gpt3__education section__margin' id='education'>
      <div className='education_section_title'>
        <h1>
          Education
        </h1>
      </div>
      <div className='gpt3__education-container'>
        <Feature title='McGill University, Montreal' text='Bachelor of Commerce, Major in Finance, Minor in Computer Science' />
        <Feature title='Lycée Albert 1er, Monaco' text='Baccalauréat ES, Mention Très Bien'/>
      
      </div>

    </div>
  )
}

export default Education