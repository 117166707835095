import React from 'react';

import { CTA, Navbar, Brand, Contact } from './components';
import { Footer, Blog, Possibility, Features, AboutMe, Header, Education } from './containers';
import './App.css';


const App = () => {
  return (
    <div className="App">
      <div className='gradient__bg'>
        <Navbar />
        <Header />
      
        <AboutMe />
        <Education />
        <Contact />
        
      </div>
    </div>
  )
}

export default App