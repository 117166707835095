import React from 'react';
import './header.css';
import linkedin from '../../assets/linkedin.png';
import github from '../../assets/github.png';


const Header = () => {
  return (
    <div className='header section__padding' id='home'>
      <div className='header-content'>
        <div className='header-content-title'> 
          <h1 className='gradient__text'>
            Hi! I'm Alexandre Saussier
          </h1>
          <h2 className='gradient__text'>
            Welcome to my personal website
          </h2>
        </div>
        
        <div className='header-content-links'>
          <a href='https://www.linkedin.com/in/alexandre-saussier-171b0b152/' target='_blank'>
            <img src={linkedin} height='50' width='50'/>
          </a>
          <a href='https://github.com/alexsaussier' target='_blank'>
            <img src={github} height='50' width='50'/>
          </a>
        </div>
        
        <div className='header-content-bio'> 
          <p>
            Hi and welcome. My name is Alex, 
          </p>
          <p> 
            I am a developer in my free-time, and work in product at nChain by day.
          </p>
          <p> 
            Previously, I worked as a consultant at Deloitte Canada.
          </p>
          <p>
            I hold a Bachelor of Commerce (BComm.) from McGill University, 
            where I pursued a major in Finance and a minor in Computer Science.
          </p>
          <p>
            Click on my Github link to take a look at my latest public projects, and feel free to send me an email using the form below.
            Talk soon!
          </p>
        </div>

      </div>
    </div>
  )
}

export default Header