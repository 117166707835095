import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';


export const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_qnhwsze', 'template_klzybts', form.current, 'Yl1auHoVY9FK3NZTR')
        .then((result) => {
            console.log(result.text);
            console.log("message sent")
            e.target.reset();
        }, (error) => {
            console.log(error.text);
        });
    }

  return (
    <div className='contact section__ margin' id='contact'>
        <h1>
            Contact Me
        </h1>
        <form ref={form} onSubmit={sendEmail}>
            <div className='user_input'>
                <label>Name</label>
                <input type="text" name="user_name" placeholder='Your name'/>
            </div>
      
            <div className='user_input'>
                <label>Email</label>
                <input type="email" name="user_email" placeholder='example@email.com'/>
            </div>
            
            <div className='user_input'>
                <label>Message</label>
                <textarea name="message" rows="4" placeholder='Your message'/>

                <button type="submit" value="Send">
                    Send
                </button>

            </div>

            

        </form>
    </div>
    
  );
};

export default Contact

